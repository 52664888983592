import styled from 'styled-components';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: block;
  position: relative;
  will-change: auto;
  z-index: 1;

  &.template-loggedin--fixed {
    overflow: hidden;
    max-height: 100vh;
    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      overflow: unset;
      max-height: unset;
    }
  }

  .template-loggedin {
    &__main-wrapper {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      overflow-y: auto;
      display: block;
      position: relative;
      z-index: 1;

      scrollbar-color: '#868585' '#DBDBDB';
      scrollbar-width: thin;

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: ${({ theme }) => theme.colorPallete.base[100]};
      }

      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colorPallete.base[300]};
        border-radius: 2.5px;
      }

      background-color: ${(props) => props.theme.colorSystem.black};
      color: ${(props) => props.theme.colorSystem.white};
    }

    &__header {
      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        padding-bottom: 8rem;
      }

      &--desk {
        display: none;

        position: fixed;
        top: 0;
        left: 0%;

        width: 100%;
        z-index: 9;

        @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
          display: block;
        }
      }
      &--mobile {
        display: block;

        position: fixed;
        top: 0;
        left: 0%;

        width: 100%;
        z-index: 9;

        @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
          display: none;
        }
      }
    }

    &__sidebar {
      position: fixed;
      top: 0;
      left: 0;

      display: none;
      width: 70px;
      height: 100%;

      z-index: 10;

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        display: block;
      }
    }
    &__content {
      width: 100%;
      height: 100%;
      min-height: 100vh;

      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 100%;
    }

    &__footer {
      &--desk {
        display: block;
        margin-bottom: 7rem;

        @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
          display: block;
          margin-bottom: 0;
        }
      }
      &--mobile {
        display: block;

        position: fixed;
        bottom: 0;
        left: 0%;

        width: 100%;
        z-index: 9;

        @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
          display: none;
        }
      }
    }

    &__hidden-menu {
      position: fixed;
      top: 100%;
      left: 0;

      width: 100%;
      height: 100vh;
      z-index: 99;

      transition: all 0.3s ease-in-out;

      &--active {
        top: 0;
        transition: all 0.3s ease-in-out;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        display: none;
      }
    }

    &__hidden-search {
      position: fixed;
      top: 100%;
      left: 0;

      width: 100%;
      height: 100vh;
      z-index: 99;

      transition: all 0.3s ease-in-out;

      &--active {
        top: 0;
        transition: all 0.3s ease-in-out;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        display: none;
      }
    }
  }

  &.template-loggedin--modal-active {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.8);

    transform: perspective(99.1vw) scale(0.91);
    transition: all 0.4s ease-in-out;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
  }

  &.template-loggedin--fixed-modal {
    overflow: hidden;
    max-height: 100vh;

    .template-loggedin__main-wrapper {
      overflow: hidden !important;
      max-height: 100vh;
      height: 100vh;
    }
  }

  .template-loggedin__main-wrapper {
    overflow-x: hidden;
  }
`;

export { Wrapper };
export default Wrapper;
