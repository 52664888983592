import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import {
  Head,
  HeaderDesk,
  HeaderMobile,
  HeaderTablet,
  MenuMobile,
  Footer,
  NavBottom,
  UpdateUserModal,
  SearchMobile,
} from 'containers';

import { Alert, UpdateRenewalModal } from '@components';

import { useWindowSize } from 'helpers/useWindowSize';

import * as S from './LoggedIn.styles';

interface LoggedInProps {
  children: any;
  titlePage: string;
  refreshToken?: string;
  modalActive: any;
  showingModal?: boolean;
}

const LoggedIn = ({
  children,
  titlePage = '',
  refreshToken = '',
  modalActive = false,
  showingModal = false,
}: LoggedInProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showRenewalModal, setShowRenewalModal] = useState(false);

  const { route } = useRouter();

  //@ts-ignore
  const { userData } = useSelector((state) => state.userReducer);
  const { width } = useWindowSize();
  const isMobile = !!width && width < 768;
  const isTablet = !!width && width < 1280 && width >= 768;

  const testUserPendencies = (restriction) => {
    switch (restriction) {
      case 'document':
        return 'Por favor, atualize seus dados pessoais';
      case 'address':
        return 'Por favor, atualize seu endereço';
      default:
        return 'Por favor, atualize seus dados financeiros';
    }
  };

  const title =
    titlePage !== '' ? `${titlePage} — meuSucesso.com` : `meuSucesso.com`;

  /**
   * @param {string} email
   * Start LGPD accept popup from logged site.
   */
  async function fetchLGPD(email) {
    if (typeof email !== 'undefined') {
      //@ts-ignore
      if (typeof window.WiserLGPDAccept !== 'undefined') {
        //@ts-ignore
        window.WiserLGPDAccept.start(email);
      }
    }
  }

  useEffect(() => {
    fetchLGPD(userData?.email);
  }, []);

  useEffect(() => {
    fetchLGPD(userData?.email);
    const hasShownUpdateRenewalModal = sessionStorage.getItem(
      'hasShownUpdateRenewalModal',
    );
    if (
      route === '/home' &&
      userData?.should_update_billing &&
      hasShownUpdateRenewalModal !== 'true'
    )
      setShowRenewalModal(true);
  }, [userData]);

  return (
    <>
      {route !== '/perfil' && userData && (
        <>
          <UpdateUserModal
            //@ts-ignore
            visible={
              !!userData.restriction &&
              userData.restriction !== 'unpaid' &&
              (userData.complient === 'active_complient' || !userData.complient)
            }
            message={testUserPendencies(userData.restriction)}
          />
        </>
      )}
      {/* @ts-ignore */}
      <Head title={title} />
      <S.Wrapper
        className={`template-loggedin ${
          showMenu || showSearch ? `template-loggedin--fixed` : ``
        }
          ${modalActive ? `template-loggedin--modal-active` : ``}

          ${showingModal ? `template-loggedin--fixed-modal` : ``}`}>
        <UpdateRenewalModal
          showRenewalModal={showRenewalModal}
          setShowRenewalModal={setShowRenewalModal}
        />
        <div className="template-loggedin__main-wrapper">
          <div className="template-loggedin__content">
            <div className="template-loggedin__header">
              {isTablet ? (
                <div className="template-loggedin__header--desk">
                  <HeaderTablet refreshToken={refreshToken} />
                </div>
              ) : isMobile ? (
                <div className="template-loggedin__header--mobile">
                  <HeaderMobile handleShowMenu={setShowMenu} />
                </div>
              ) : (
                <div className="template-loggedin__header--desk">
                  <HeaderDesk refreshToken={refreshToken} />
                </div>
              )}
            </div>

            {userData && userData.should_update_billing && (
              <Alert
                text="Precisamos atualizar seus dados cadastrais."
                backgroundColor="#F2B20C"
                buttonLink="https://wa.me/5541988014291"
                buttonText="Atualizar"
              />
            )}

            <section className="template-loggedin__main-content">
              {children}
            </section>

            <div className="template-loggedin__footer">
              <div className="template-loggedin__footer--desk">
                <Footer />
              </div>
              <div className="template-loggedin__footer--mobile">
                <NavBottom handleShowSearch={setShowSearch} />
              </div>
            </div>
          </div>
          <div
            className={`template-loggedin__hidden-menu ${
              showMenu ? `template-loggedin__hidden-menu--active` : ``
            }`}>
            <MenuMobile handleShowMenu={setShowMenu} />
          </div>

          <div
            className={`template-loggedin__hidden-search ${
              showSearch ? `template-loggedin__hidden-search--active` : ``
            }`}>
            <SearchMobile
              handleShowSearch={setShowSearch}
              searchState={showSearch}
              refreshToken={refreshToken}
            />
          </div>
        </div>
      </S.Wrapper>
    </>
  );
};

export default LoggedIn;
export { LoggedIn };
