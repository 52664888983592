import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Logo, Text } from '@components';

// Tema
import { main } from '@themes';

// Containers
import { Head } from '@containers';

// Styles
import { Wrapper } from './EditProfile.styles';

const EditProfile = ({ children, titlePage, highlight }) => {
  const { userData } = useSelector((state) => state.userReducer);

  const title =
    titlePage !== '' ? `${titlePage} — meuSucesso.com` : `meuSucesso.com`;

  const cssClasses = classNames({
    'edit-profile': true,
  });

  /**
   * @param {string} email
   * Start LGPD accept popup from logged site.
   */
  async function fetchLGPD(email) {
    if (typeof email !== 'undefined') {
      if (typeof window.WiserLGPDAccept !== 'undefined') {
        window.WiserLGPDAccept.start(email);
        console.log('LGPD starting...');
      }
    }
  }

  useEffect(() => {
    fetchLGPD(userData?.email);
  }, []);

  useEffect(() => {
    fetchLGPD(userData?.email);
  }, [userData]);

  return (
    <Wrapper className={cssClasses}>
      <div className="edit-profile__fixed-corner">
        <div className="edit-profile__presentation">
          <div className="edit-profile__presentation--content">
            <Logo size="280px" />
            {highlight !== '' ? (
              <Text
                as="p"
                dsize="2rem"
                size="2rem"
                color={main.colorPallete.white}
                family={main.fonts.fontFamily.serif}>
                {highlight}
              </Text>
            ) : null}
          </div>
        </div>
      </div>

      <div className="edit-profile__content-form">
        <div className="edit-profile__content-form__children">{children}</div>
        <div className="edit-profile__content-form--decoration" />
      </div>
    </Wrapper>
  );
};

EditProfile.propTypes = {
  children: PropTypes.element,
  titlePage: PropTypes.string,
  highlight: PropTypes.string,
};

EditProfile.defaultProps = {
  children: null,
  titlePage: '',
  highlight: '',
};

export default EditProfile;
export { EditProfile };
