import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
    overflow: unset;
    min-height: unset;
  }

  .edit-profile {
    &__fixed-corner {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colorSystem.white};
      position: relative;
    }

    &__presentation {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      height: 100vh;

      background-color: ${(props) => props.theme.colorSystem.white};
      background-image: url('/assets/images/pattern-ms-dark.svg');
      background-repeat: repeat;
      background-position: right center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;

      &--content {
        width: calc(100% - 6rem);
        max-width: 38rem;
        margin: 11rem 17rem auto auto;
        padding: 2rem 4rem 2rem 2rem;

        @media (max-width: ${(props) => props.theme.breakpoints.big}) {
          margin: 11rem auto auto auto;
          width: 100%;
        }

        p {
          margin-top: 2rem;

          @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
            display: none;
          }
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
        display: none;
      }
    }

    &__content-form {
      padding-bottom: 8rem;
      display: flex;
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: auto;

      @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 36rem;
        /* min-height: 100vh; */
        height: auto;
        overflow: unset;
      }

      &__children {
        position: absolute;
        width: 100%;
        height: 100%;

        @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
          position: relative;
          display: flex;
        }
      }

      &--decoration {
        display: none;

        @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
          display: block;
          margin-top: 8rem;
          width: 100%;
          height: 28rem;
          position: absolute;
          bottom: 0;
          left: 0;

          background: url('/assets/images/pattern-ms-dark.svg') center repeat;
          background-size: 100% auto;
        }
      }
    }
  }
`;

export { Wrapper };
