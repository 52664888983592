import { PaginationQueryDto } from './paginationQueryDto';

export enum SortOrder {
  CreatedAtAsc = 'createdAt:asc',
  CreatedAtDesc = 'createdAt:desc',
  CourseTitleAsc = 'courseTitle:asc',
  CourseTitleDesc = 'courseTitle:desc',
}

export type GetCertificatesListQueryDto = PaginationQueryDto & {
  studentId?: string;
  sortOrder?: SortOrder;
};
