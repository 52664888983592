import { Head } from 'containers';

import * as S from './PublicRoute.styles';
import { Logo } from '@components';
import { useWindowSize } from '@helpers/useWindowSize';

interface PublicRouteProps {
  children: any;
  titlePage: string;
}

const PublicRoute = ({ children, titlePage = '' }: PublicRouteProps) => {
  const title =
    titlePage !== '' ? `${titlePage} — meuSucesso.com` : `meuSucesso.com`;

  const { width } = useWindowSize();
  const isMobile = !!width && width < 768;

  return (
    <>
      {/* @ts-ignore */}
      <Head title={title} />
      <S.Wrapper>
        <S.Header>
          <a
            href="https://meusucesso.com/"
            className="ms-logo"
            id="header-logo">
            <Logo color="#fff" size={isMobile ? '120px' : '150px'} />
          </a>
          <S.LoginButton href="/login">Login</S.LoginButton>
        </S.Header>
        <S.Content>{children}</S.Content>
      </S.Wrapper>
    </>
  );
};

export default PublicRoute;
export { PublicRoute };
