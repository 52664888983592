import Link from 'next/link';
import { styled, css } from 'styled-components';

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  display: block;
  position: relative;
  will-change: auto;
  z-index: 1;

  scrollbar-color: '#868585' '#DBDBDB';
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${({ theme }) => theme.colorPallete.base[100]};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colorPallete.base[300]};
    border-radius: 2.5px;
  }

  background-color: ${(props) => props.theme.colorSystem.black};
  color: ${(props) => props.theme.colorSystem.white};
`;

export const Header = styled.nav`
  ${({ theme }) => css`
    height: 80px;
    display: flex;
    width: 100%;
    gap: 2vw;
    justify-content: space-between;
    align-items: center;
    padding-inline: 48px;
    background-color: ${theme.colorPallete.base.default};
    position: fixed;
    top: 0;
    left: 0%;
    z-index: 9;

    box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.45);

    @media (max-width: 768px) {
      padding-inline: 24px;

      #header-logo {
        max-width: 150px;
      }
    }
  `}
`;

export const LoginButton = styled(Link)`
  ${({ theme }) => css`
    width: 140px;
    padding: 12px;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid ${theme.colorPallete.white};
    text-align: center;
    cursor: pointer;

    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.sm};
    font-weight: ${theme.fonts.fontWeight.bold};

    @media (max-width: 768px) {
      width: 100px;
      padding: 8px;
    }
  `}
`;

export const Content = styled.section`
  margin-top: 80px;
  padding: 24px;
`;
