import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from '@components';

// Tema
import { main } from '@themes';

// Containers
import { Head } from '@containers';

// Styles
import { Wrapper } from './SignUp.styles';

const SignUp = ({ children, titlePage, highlight }) => {
  const title =
    titlePage !== '' ? `${titlePage} — meuSucesso.com` : `meuSucesso.com`;

  const cssClasses = classNames({
    signup: true,
  });

  return (
    <>
      <Head title={title} />
      <Wrapper className={cssClasses}>
        <div className="signup__fixed-corner">
          <div className="signup__presentation">
            <div className="signup__presentation--content">
              {highlight !== '' ? (
                <Text
                  as="p"
                  dsize="2rem"
                  size="2rem"
                  color={main.colorPallete.white}
                  family={main.fonts.fontFamily.serif}>
                  {highlight}
                </Text>
              ) : null}
            </div>
          </div>
        </div>

        <div className="signup__content-form">
          {children}
          <div className="signup__content-form--decoration" />
        </div>
      </Wrapper>
    </>
  );
};

SignUp.propTypes = {
  children: PropTypes.element,
  titlePage: PropTypes.string,
  highlight: PropTypes.string,
};

SignUp.defaultProps = {
  children: null,
  titlePage: '',
  highlight: '',
};

export default SignUp;
export { SignUp };
